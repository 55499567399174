.project__header > h1 {
  display: flex;
  justify-content: center;
  color: white;
  font-size: 4rem;
  letter-spacing: 9px;
  margin-top: 10%;
  font-family: Prata;
  @media (max-width: 450px) {
    font-size: 2rem;
    margin-top: 45%;
    text-align: center;
  }
}

.project__main {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 10% 0;
  flex-wrap: wrap;
  @media (max-width:450px) {
    display: grid;
  }
}

.project__main > img {
  width: 26%;

  @media (max-width: 420px) {
    width: 50%;
    margin-left: 24%;
    margin-top: 5%;
    object-fit: contain;
  }
}

.main__text {
  width: 30%;
  margin-left: -16%;
  @media (max-width: 450px) {
    margin-left: 29%;
  }
}

.main__text > h2 {
  width: 671px;
  height: 30px;
  left: 952px;
  top: 1021px;

  font-family: Prata;
  font-style: normal;
  font-weight: normal;
  font-size: 36px;

}

.main__text > p {
  width: 700px;
  height: 299px;
  left: 952px;
  top: 1099px;

  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 279%;
  @media (max-width: 450px) {
    width: 289px;
    height: 545px;
    left: 934px;
    top: 1099px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 228%;
  }
}

//.project__main:nth-child(-n+2) {
//  margin: 0 4%;
//}

.project__images {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  @media (max-width: 450px) {
    display: grid;
  }
}

.project__images > img {
  width: 24%;
  @media (max-width: 450px) {
    width: 100%;
    margin: 5% 0;
  }
}

.svg__row {
  display: flex;
  justify-content: flex-start;
}

#svg__two {
  position: absolute;
  display: flex;
  margin-top: -1.8%;
}

.svg__row__right {
  //position: absolute;
  display: flex;
  margin-top: -1.8%;
  right: 0;
}

.project__page {
  background-color: white;
  width: 100%;
  height: 100vh;
  overflow-x: hidden;
  position: relative;
}

