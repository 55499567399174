#verticalSlider {
  height: 100%;
}

.paddingTop {
  padding-top: 3%;
}

.tns-controls {
  /*display: none ;*/
  transform: rotate(-90deg);
  transform-origin: right, top;
  -ms-transform: rotate(-90deg);
  -ms-transform-origin:right, top;
  -webkit-transform: rotate(-90deg);
  -webkit-transform-origin:right, top;
  position: absolute;
  z-index: 50;
  display: table-footer-group;
  float: right;
}

.header__text {
  padding: 0;
  position: absolute;
  margin: 19% 0 0 4%;
  color: white;
  text-transform: uppercase;
  letter-spacing: 5px;
  font-size: 120px;
  font-weight: 400;
  //font-family: ;

  @media only screen and (max-width: 480px) {
    text-align: left;
    margin-top: 240px;
    font-size: 40px;
  }

  @media only screen and (min-width: 1024px) {
    margin: 18% 0 0 4%;
  }
}

.header__line {
  position: absolute;
  margin: 20% 0 0 4%;
  color: white;
  background-color: white;
  width: 32%;
  height: 3px !important;
  opacity: 1;

  @media only screen and (max-width: 480px) {
    text-align: left;
    margin-top: 255px;
    font-size: 40px;
    width: 60%;
  }

  @media only screen and (min-width: 1024px) {
    margin: 19% 0 0 4%;
  }
}

 .bg-Image {
   @media only screen and (max-width: 480px) {
     object-fit: contain;
     background-position: center;
     background-repeat: no-repeat;
   }
 }

.header__button {
  width: 800px;
  position: absolute;
  margin: 25% 0 0 4.5%;
  @media only screen and (min-width: 1024px) {
    //margin: 20% 0 0 4.5%;
  }
}

#header__router {
  display: flex;
  align-items: center;
  //justify-content: center;
}

.header__router__text {
  font-weight: lighter;
  letter-spacing: 3px;
  font-size: 48px;
  color: white;
  text-decoration: none;
  text-transform: uppercase;

  @media only screen and (max-width: 480px) {
    text-align: left;
    margin-top: 220px;
    font-size: 40px;
    width: 74%;
  }
}

*{
  -ms-overflow-style: none;
}
::-webkit-scrollbar {
  display: none;
}

//
//@media screen and (min-width: 480px)  {
//  .header__text {
//    margin: 30% 0 0 4%;
//    color: white;
//    text-transform: uppercase;
//    letter-spacing: 5px;
//    font-size: 120px;
//    //text-align: center;
//    font-weight: lighter;
//    //width: 900px;
//  }
//}
