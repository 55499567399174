.about__page {
  overflow-x: hidden;
  position: relative;
}

.about__header {
  text-align: center;
  font-family: Prata;
  font-style: normal;
  font-weight: normal;
  font-size: 64px;
  line-height: 87px;
  //text-align: center;
  letter-spacing: 0.17em;
  text-transform: uppercase;

  @media (max-width: 450px) {
    font-family: Prata;
    font-style: normal;
    font-weight: normal;
    font-size: 36px;
    line-height: 49px;
    text-align: center;
    letter-spacing: 0.17em;
    text-transform: uppercase;
  }
}


.team__image {
  width: 480px;
  height: 520px;

  @media (max-width: 450px) {
    width: 100%;
    height: auto;
  }
}

.team__paragraph {
  width: 100%;
  font-family: Montserrat, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 279%;

  @media (max-width:450px) {
    margin-right: auto;
    margin-left: auto;
    width: 85%;
  }
}

.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  width: 92%;

  @media (max-width: 450px) {
    display: grid;
  }
}

.flexTwo {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 74%;
  margin-left: auto;
  flex-wrap: wrap;

  @media (max-width: 450px) {
    width: 100% ;
  }
}

#inside > img {
  width: 393px;
  height: 506px;

  @media (max-width: 450px) {
    width: 100%;
  }
}

#inside > h4 {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  line-height: 22px;
@media (max-width: 450px) {
  margin: 8px 0;

  //font-size: 18px;
}
}

#inside > h5 {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;

  color: #C09553;
  @media (max-width: 450px) {
    margin: 8px 0;
    font-size: 17px;
  }
}


.minusMargin {
  margin-top: -7% !important;
  @media (max-width: 450px) {
    margin-top: 20% !important;
  }
}

.content {
  margin: 5% 0 0 0 !important;
}

.about__work {
  display: grid;
  //justify-content: center;
  justify-self: center;

  width: 35%;
}

.about__work > img {
  width: 376px;
  height: 171px;
  margin: 0 0 7% 0;
}

.about__work > p {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 279%;
}

#workImage {
  width: 979px;
  height: 709px;
}

#fullImg {
  width: 100%;
  height: 55vh;
  margin-top: 10%;
}


.howWeWork {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 15%;
}

#svg__row__right {
  display: flex;
  justify-content: flex-end;
}

#svg__first {
  position: absolute;
  margin-top: -1.8%;
}